export const menuItems = [
  {
    name: "lang",
    label: "menu.current-lang",
    items: [
      { name: "en", label: "English" },
      { name: "de-DE", label: "Deutsch DE" },
      { name: "de-CH", label: "Deutsch CH" },
      { name: "fr", label: "Francais (vite)" },
    ],
  },
  {
    name: "#",
    label: "global.menue-home",
    isExternal: false,
  },
  {
    name: "/#features",
    label: "global.menue-features",
    isExternal: false,
    onSamePage: true,
  },
  {
    name: "/#prices",
    label: "global.menue-pricing",
    isExternal: false,
    onSamePage: true,
  },
  {
    name: "https://trustify.tawk.help/",
    label: "global.menue-wiki",
    isExternal: true,
  },
  {
    name: "contact",
    label: "global.menue-contact",
    isExternal: false,
  },
];
